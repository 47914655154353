import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export const User = () => {
  const name = useSelector((state) => state.login?.user?.name);
  const email = useSelector((state) => state.login?.user?.email);
  const prevDate = useSelector((state) => state.login?.user?.previousAccess);
  const prevChannel = useSelector((state) => state.login?.user?.previousChannel);

  const formatPrevDate = (dateString) => {
    if (!dateString) return '';
    return format(new Date(dateString), "dd/MM/yyyy HH:mm:ss", { locale: es });
  };

  return (
    <div className="nav-menu-item user-profile">
      <div className="user-profile__info">
        <div className="user-profile__info__name">{name} </div>
        <div className="user-profile__info__email">{email} </div>
        {prevDate && prevChannel && (
          <div className="user-profile__info">Último acceso:</div>
        )}
        {prevDate && prevChannel && (
          <div className="user-profile__info">{formatPrevDate(prevDate)} ({prevChannel})</div>
        )}
      </div>
    </div>
  )
}
