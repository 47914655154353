import React, { useContext } from 'react';
import { useSelector } from "react-redux";
import { Navbar } from './components/Navbar';
import { getSections } from '../../_shared/getSections';
import { PermissionsContext } from 'contexts/permissionsContext';

export const DashboardHeader = ({ brand, openModal, closeModal }) => {
  const user = useSelector((state) => state.login.user);
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const sections = getSections(userPermissions, featureFlags, user);

  return (
    <div className="dashboard-header-wrapper">
      <div className="dashboard-header">
        <Navbar
          openModal={openModal}
          closeModal={closeModal}
          sections={sections}
          brand={brand}
        />
      </div>
    </div>
  );
};
