import React, { useState, useEffect } from 'react';
import 'moment-timezone';
import Loader from "components/Loader";
import { useSelector } from 'react-redux';
import { fetchResource } from "api-provider";
import i18n from 'utils/i18n';

export const GeoStore = (props) => {
    const token = useSelector((state) => state.api.token);
    const { subsidiaryId } = useSelector((state) => state.login.user);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        const resources = {
            geostore: (token, subsidiaryId) => ({
                endpoint: `geo-stores/redirect`,
                method: 'POST',
                formData: {
                    token,
                    subsidiary_id: subsidiaryId
                },
                headers: { "X-Authentication-Token": token },
            })
        }

        fetchResource(resources.geostore(token, subsidiaryId))
            .onSuccess(data => window.open(data.url, '_blank'))
            .onFailure(error => console.log(error))

        setLoading(false);
    }, [token, subsidiaryId]);

    return (
        <>
            <div style={{ cursor: 'pointer' }}>
                {loading && <Loader />}
                <span style={{fontWeight: 500, fontSize: '1.2em'}}>{`Redirigiendo a ${i18n.t('dashboard.sections.geo-store.title')}...`}</span><br />
                Por favor, permita a su navegador <span style={{fontWeight: 500, color: "#e4002b", opacity: ".8"}}>abrir ventanas emergentes</span>
            </div>
        </>
    );
}
