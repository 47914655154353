import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert,
} from "reactstrap";

import __ from "utils/i18n";
import { DashboardModal } from "containers/Dashboard/components/DashboardModal";
import UnsubcribeModal from "containers/Dashboard/modals/UnsubcribeModal";
import { fetchResource } from "api-provider";
import { SimpleForm, SelectField, TextField } from "components/forms";
import { editUser } from "containers/Login/actions";
import { getConfig } from "config";
import { termsAndConditionsUrl, onboardingUrl, offboardingUrl, tenantId } from "config/envConfig";
import { PermissionsContext } from "contexts/permissionsContext";
import Loader from "components/Loader";
import "../../../styles/newStyles/ConfigurationModal.scss";
import { isValidToken } from "containers/Login/resources";
import { loginSuccess } from "containers/Login/actions";
import { OneLineWarning } from "../components/OneLineWarning";

const userLanguage = localStorage.getItem("locale");
const showMaxMonthlyAmount = getConfig('customConfig.showMaxMonthlyAmount', true);

const resources = {
  resetPassword: ({ email }) => ({
    endpoint: "users/resetPassword.json",
    method: "POST",
    formData: {
      email,
      channel: "web",
      deviceId: "web",
      deviceModel: "web",
      deviceOS: "web",
    },
  }),
  edit: (file, user) => {
    if (file !== null) {
      const id = user.id;
      const token = user.token;
      return {
        endpoint: "subsidiaries/uploadImage.json",
        method: "POST",
        formData: {
          userId: id,
          token: token,
          subsidiaryImage: file,
          channel: "web",
          deviceId: "web",
          deviceModel: "web",
          deviceOS: "web",
        },
      };
    }
    return false;
  },
  deleteMerchantImage: (user) => {
    return {
      endpoint: "subsidiaries/uploadImage.json",
      method: "POST",
      formData: {
        userId: user.id,
        token: user.token,
        modified: true,
        file: null,
        url: null,
        deleteProfileImage: 1,
        subsidiaryImage: true,
        channel: "web",
        deviceId: "web",
        deviceModel: "web",
        deviceOS: "web",
      },
    };
  },
  unsubscribe: ({ token, tenantId }) => ({
    endpoint: "/api/v1/users/unsubscribe",
    method: "POST",
    headers: {
      "content-type": "application/json",
      "x-client-id": tenantId,
      "x-authentication-token": token,
    },
    proxy: false,
    data: {
      channel: "web",
      deviceId: "web",
      deviceModel: "web",
      deviceOS: "web",
    },
  }),
  accountDetails: (token) => ({
    headers: {
      'X-Authentication-Token': token,
    },
    endpoint: 'account/type',
    method: 'GET'
  })
};

export const resolveAccountype = (accountType) => {
  if (accountType === "OCCASIONAL") {
    return __("dashboard.accountType.occasional");
  }
  if (accountType === "PROFESSIONAL") {
    return __("dashboard.accountType.professional");
  }
  if (accountType === "COMPANY") {
    return __("dashboard.accountType.company");
  }
};

export const passToProfesional = (token) => {
  localStorage.clear();
  window.location.href = `${onboardingUrl}/updateAccountType/${token}`;
};

const ConfigurationModal = (props) => {
  const token = useSelector((state) => state.api.token);

  const [updatedImage, setUpdatedImage] = useState(false);
  const [errorUpdateImage, setErrorUpdateImage] = useState(false);
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(props.user.profileImageUrl || null);
  const [resetPasswordStatus, setResetPasswordStatus] = useState(null);
  const [resetPassword, setResetPassword] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [isFetching, setIsFeching] = useState(false);
  const [hasSendUnsubscribeEmail, setHasSendUnsubscribeEmail] = useState(false);
  const [maxMonthlyAmount, setMaxMonthlyAmount] = useState(50000);

  const [userDefaultValues, setUserDefaultValues] = useState({
    email: props?.user?.email,
    language: userLanguage === "es" ? "es" : "en",
    exchangeRate: props?.user?.currencies[0].exchangeRate,
  });
  const [languageSelected, setLanguageSelected] = useState();

  const { userPermissions, featureFlags } = useContext(PermissionsContext);

  const jwtEnabled = featureFlags?.includes("auth_server_enabled") || featureFlags?.includes("keycloak_enabled");
  const isMultiLanguageEnabled = featureFlags?.includes("multilanguage");
  const isEditImageEnabled = userPermissions?.includes(
    "config_modal_action_image_edit"
  );
  const isExchangeRateInputEnabled = featureFlags?.includes('exchange_rate');
  const isUnsubscriptionEnabled =
    featureFlags?.includes("user_unsubscribe") &&
    userPermissions?.includes("user_unsubscribe");

  const showtermsAndConditions = getConfig("customConfig.termsAndConditions");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.user);
  const { accountCode, fiscalId, documentType, documentNumber } = userData;

  const {
    showModal,
    showDeleteModal,
    hideModal,
    closeConfigModal,
    isOpen,
    user,
  } = props;

  let dropzoneRef;

  const dropzoneClasses = classNames({
    dropzone: true,
    dropzoneHasPreview: !!url,
  });

  const onImageDrop = (accepted) => {
    if (accepted.length) {
      setFile(accepted[0]);
      setUrl(URL.createObjectURL(accepted[0]));
    } else {
      setErrorUpdateImage(true);
    }
  };

  const handleDataRefresh = () => {
    fetchResource(isValidToken(token))
      .onSuccess((data) => {
        const filteredData = Object.fromEntries(
          Object.entries(data).filter(
            ([key, value]) =>
              value &&
              !key.includes("userPermissions") &&
              !key.includes("featureFlags") &&
              !key.includes("serverAuth")
          )
        );
        filteredData.token = token;
        dispatch(loginSuccess({ ...userData, ...filteredData }));

      })
      .onFailure((error) => {
        console.log(error);
      });
  };

  const handleDeleteMerchantImage = (user) => {
    fetchResource(resources.deleteMerchantImage, user, props.api).onSuccess(
      (result) => {
        setFile(null);
        setUrl(null);
      }
    );
  };

  const handleUnsubscribe = async () => {
    setUnsubscribing(false);
    let unsubscriptionToken = token;
    setIsFeching(true);

    if (jwtEnabled) {
      const res = await fetchResource(isValidToken(token));
      unsubscriptionToken = res.token;
    }

    if (offboardingUrl) {
      const unsubscriptionUrl = `${offboardingUrl + getConfig('customConfig.offBoardingCustomPath') + unsubscriptionToken}`
      window.location.replace(unsubscriptionUrl);
    } else {
      try {
        await fetchResource(resources.unsubscribe, { token: unsubscriptionToken, tenantId });
        setHasSendUnsubscribeEmail(true);
      } catch (error) {
        console.error(error);
      }
    }
    setIsFeching(false);
  }

  const toResetPassword = (email) => {
    setIsFeching(true);
    fetchResource(resources.resetPassword, { email: email }, props.api)
      .onSuccess((response) => {
        setIsFeching(false);
        setResetPassword(true);
        setResetPasswordStatus("OK");
      })
      .onFailure((error) => {
        setIsFeching(false);
        setResetPasswordStatus("ERROR");
      });
  };

  const setImage = (data) => {
    props.user.profileImageUrl = data.profileImageUrl;
    props.dispatch(editUser(props.user));
    setUpdatedImage(true);
    setFile(null);
  };

  const handleSubmit = () => {
    if (languageSelected) {
      localStorage.setItem("locale", languageSelected);
      closeConfigModal();
    }

    if (file) {
      setIsFeching(true);
      fetchResource(resources.edit(file, props.user))
        .onSuccess((result) => {
          setImage(result);
          setIsFeching(false);
          handleDataRefresh();
        })
        .onFailure((error) => {
          setIsFeching(false);
        });
    }
  };

  const showAccountType = getConfig('customConfig.showAccountType');
  const showMerchantArea = getConfig('customConfig.showMerchantArea');
  const showAccountNameInMerchantArea = getConfig('customConfig.showAccountNameInMerchantArea');
  const showFiscalIdInMerchantArea = getConfig('customConfig.showFiscalIdInMerchantArea');

  useEffect(() => {
    if (user.accountType !== 'COMPANY') {
      fetchResource(resources.accountDetails(token))
        .onSuccess((response) => {
          setMaxMonthlyAmount(response.maxMonthlyAmount);
        })
        .onFailure((error) => {
          console.log(error);
        });
    }
  }, [token, user.accountType]);

  return (
    <div>
      <DashboardModal
        name="deleteImage"
        headerText={__("dashboard.components.modal.headerText")}
        showModal={showModal}
        hideModal={hideModal}
        submitAction={() => handleDeleteMerchantImage(user)}
        cancelText={__("actions.cancel")}
        submitText={__("actions.delete")}
      >
        {__("dashboard.components.modal.confirmDeleteImage")}
      </DashboardModal>

      <DashboardModal
        name="updateImage"
        headerText={__("dashboard.components.modal.successfulImage")}
        showModal={updatedImage}
        hideModal={() => setUpdatedImage(false)}
        cancelText="Ok"
      >
        {__("dashboard.components.modal.updateImage")}
      </DashboardModal>

      <DashboardModal
        name="resetPassword"
        headerText={__("resetPassword.sentResetPassword")}
        showModal={resetPassword}
        hideModal={() => {
          setResetPassword(false);
        }}
        cancelText="Ok"
      >
        {__("dashboard.components.modal.resetPasswordMessage")}
      </DashboardModal>

      <UnsubcribeModal
        name="asarasa"
        headerText={__("resetPassword.sentResetPassword")}
        isOpen={unsubscribing}
        setIsOpen={setUnsubscribing}
        onSubmit={handleUnsubscribe}
      >
      </UnsubcribeModal>

      <Modal isOpen={isOpen} toggle={() => closeConfigModal()} size="lg">
        <SimpleForm
          defaultValues={userDefaultValues}
          onChange={({ values }) => setUserDefaultValues({ ...values })}
          onSubmit={handleSubmit}
        >
          {(form) => (
            <div>
              <ModalHeader>
                {__("dashboard.components.modal.header")}
              </ModalHeader>
              <ModalBody>
                {isFetching && <Loader />}
                <div className="config-modal-subtitle">
                  {__("dashboard.components.modal.user")}
                </div>
                <div className="config-modal-card-item  config-modal-cardItem-radius">
                  <div className="config-modal-card-item-body">
                    <Row className="config-modal-no-margin">
                      <Col className="config-modal-name">
                        <div>
                          <b>{user.name}</b>
                        </div>
                        <div className="config-modal-text text-lowercase">
                          {user.email}
                        </div>
                      </Col>

                      <Col>
                        {(resetPasswordStatus === "OK") ? (
                          <Button color="success" disabled={true}>
                            <i className="mdi mdi-check mdi-16px config-modal-text-uppercase buttom-radius" />
                            {__("dashboard.components.modal.sendEmail")}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            disabled={isFetching}
                            onClick={() => toResetPassword(user.email)}
                            className="config-modal-text-uppercase buttom-radius"
                          >
                            {__("dashboard.components.modal.changePass")}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
                {showMerchantArea && (
                  <div className="config-modal-areaMerchant">
                    <div className="config-modal-subtitle">
                      {__("dashboard.sections.merchant.title")}
                    </div>
                    <div className="config-modal-card-item config-modal-cardItem-radius">
                      <div className="config-modal-card-item-body">
                        <Row>
                          <Col className="config-modal-merchant config-modal-no-margin">
                            {showAccountNameInMerchantArea && (
                              <div>
                                <b>{user.name}</b>
                              </div>
                            )}
                            {showFiscalIdInMerchantArea && (
                              <div>
                                <b>{user.fiscalId}</b>
                              </div>
                            )}
                            {user && user.subsidiaryName && (
                              <>
                                <div className="mb-2">
                                  <b>{user.subsidiaryName}</b>
                                </div>
                              </>
                            )}
                            {user && accountCode && (
                              <div className="dashboard-card-item-detail config-modal-text">
                                {__("dashboard.components.modal.nbo")}
                                {": " + accountCode || '-'}
                              </div>
                            )}
                            {documentType && documentNumber && (
                              <div className="dashboard-card-item-detail config-modal-text">
                                {__("dashboard.components.modal.documentNumber")}
                                {": " + documentType + " " + documentNumber || '-'}
                              </div>
                            )}
                            {fiscalId && !showAccountType && (
                              <div className="dashboard-card-item-detail config-modal-text">
                                {__("dashboard.components.modal.fiscalId")}
                                {": " + fiscalId || '-'}
                              </div>
                            )}
                          </Col>
                          <Col>
                          </Col>
                          <Col md="4">
                            {user.accountType === "OCCASIONAL" &&
                              getConfig(
                                "customConfig.onboardingUpdateAccountLink"
                              ) && (
                                <Button
                                  className="config-modal-btn-link"
                                  onClick={() => passToProfesional(`${user.token}`)}
                                >
                                  {__(
                                    "dashboard.components.modal.PassAccountToProfession"
                                  )}
                                </Button>
                              )}
                          </Col>
                          <Col md="8"></Col>
                        </Row>

                        {showAccountType && (
                          <Row>
                            <Col className="col config-modal-text config-modal-no-margin">
                              <div>
                                {__(
                                  "dashboard.components.modal.accountType"
                                )}
                                {__(resolveAccountype(user.accountType))}
                              </div>
                              {(showMaxMonthlyAmount) ? (
                                <div>
                                  {user.accountType === "OCCASIONAL" &&
                                    __("dashboard.components.modal.limitOcassional", { amount: maxMonthlyAmount })
                                  }
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {isEditImageEnabled && (
                  <>
                    <div className="config-modal-subtitle">
                      {__("dashboard.components.modal.header")}
                    </div>
                    <div className="config-modal-image-section">
                      <div className="config-dropzone-container">
                        <Dropzone
                          className={dropzoneClasses}
                          ref={(node) => {
                            dropzoneRef = node;
                          }}
                          activeClassName="dropzoneActive"
                          rejectClassName="dropzoneReject"
                          accept="image/jpeg, image/png"
                          maxSize={1e6}
                          onDrop={(accepted, rejected) => {
                            onImageDrop(accepted);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => {
                            if (url) {
                              return (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <img
                                    className="config-modal-image-cover"
                                    alt="Product"
                                    src={url}
                                    height="100px"
                                    width="100px"
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div className="dropzone" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <i className="mdi mdi-store" />
                                </div>
                              );
                            }
                          }}
                        </Dropzone>
                        <button
                          type="button"
                          className="config-modal-edit-icon"
                          onClick={() => {
                            dropzoneRef.open();
                          }}
                        >
                          <i className="mdi mdi-light mdi-pencil"></i>
                        </button>
                        {false && (
                          <div className="dropzone__buttons">
                            <Button
                              color="secondary"
                              type="button"
                              onClick={showDeleteModal}
                            >
                              {__("dashboard.components.modal.deleteImg")}
                            </Button>
                          </div>
                        )}
                      </div>
                      <span>
                        {__("dashboard.components.modal.selectImage")}
                      </span>
                    </div>
                    {errorUpdateImage && <OneLineWarning className="mt-3" message={__("dashboard.index.formatImage")} />}
                  </>
                )}
                {showtermsAndConditions && (
                  <div>
                    <div>
                      <div className="config-modal-subtitle">
                        {__("dashboard.components.modal.termsAndConditions")}
                      </div>
                    </div>

                    <div className="config-modal-card-item config-modal-card-botton">
                      <div className="config-modal-card-item-body  config-modal-termsAndConditions-Link">
                        <span className="config-modal-text-span">
                          {__(
                            "dashboard.components.modal.termsAndConditionsText"
                          )}
                        </span>
                        <a
                          href={termsAndConditionsUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {__(
                            "dashboard.components.modal.termsAndConditionsDonwload"
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {isExchangeRateInputEnabled && (
                  <Row>
                    <Col>
                      <div className="config-modal-subtitle">Tipo de cambio</div>
                    </Col>
                    <Col>
                      <div className="config-modal-exchange-rate-section">
                        <label htmlFor="exchangeRate" className="mr-1">1USD =</label>
                        <TextField
                          name="exchangeRate"
                          minlength={1}
                          maxLength={3}
                          pattern={'^[0-9]*$'}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {isMultiLanguageEnabled && (
                  <Row>
                    <Col>
                      <div className="config-modal-subtitle">
                        {__("dashboard.components.modal.language")}
                      </div>
                    </Col>
                    <Col>
                      <div className="config-modal-language-section">
                        <SelectField
                          disabled={true}
                          field="language"
                          placeholder={__(
                            "dashboard.components.form.language.placeholder"
                          )}
                          options={[
                            { label: "Español", value: "es" },
                            { label: "English", value: "en" },
                          ]}
                          onChange={(value) => setLanguageSelected(value)}
                        />
                      </div>
                    </Col>
                  </Row>
                )}

                {isUnsubscriptionEnabled && (
                  <Row>
                    <Col>
                      <div className='config-modal-subtitle'>
                        {__('dashboard.components.modal.account')}
                      </div>

                      <div className='config-modal-btn-link' onClick={() => { setUnsubscribing(true) }}>
                        <p className='config-modal-Unsubcribe-text'>
                          {__('dashboard.components.modal.unsubscribeRequest')}
                        </p>
                      </div>

                      {hasSendUnsubscribeEmail &&
                        <Alert color='success'>
                          <p className="m-0 text-center">{__('dashboard.components.modal.unsubscribeConfirmText')}</p>
                        </Alert>
                      }
                    </Col>
                  </Row>
                )}
                <hr />
              </ModalBody>
              <ModalFooter>
                <Button
                  className="config-modal-btnClose buttom-radius"
                  color="secondary"
                  onClick={() => closeConfigModal()}
                >
                  {__("actions.close")}
                </Button>
                <Button
                  className="config-modal-btnSave buttom-radius"
                  disabled={isFetching || (!languageSelected && !file)}
                  type="submit"
                  color="primary"
                >
                  {__("actions.save")}
                </Button>
              </ModalFooter>
            </div>
          )}
        </SimpleForm>
      </Modal>
    </div>
  );
};

export default ConfigurationModal;
