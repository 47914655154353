import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { isFinite, hasIn } from 'lodash';
import { Row, Col } from 'reactstrap';
import { get } from 'lodash';
import { fetchResource } from 'api-provider';
import Loader from 'components/Loader';
import { PermissionsContext } from "contexts/permissionsContext";
import { StaffCard } from '../staffChart/StaffCard';
import { ProdcutCard } from '../productsChart/ProductCard';
import { CardChart } from '../_shared/CardChart';
import __ from 'utils/i18n';
import { getConfig } from 'config';
import { DonutChart } from 'components/charts/DonutChart';
import { getResume, getSales } from '../_shared/resources';
import { EmptyCard } from './EmptyCard';

export const StartSectionContent = (props) => {
  const { mode, currencyCode } = props;

  const [error, setError] = useState(false);
  const [isStarting, setIsStarting] = useState(true);
  const [isFetchingResume, setIsFetchingResume] = useState(false);
  const [isFetchingSales, setIsFetchingSales] = useState(false);
  const [resume, setResume] = useState({});
  const [sales, setSales] = useState({});
  const [totals, setTotals] = useState({});
  const [customers, setCustomers] = useState(false);
  const [positive, setPositive] = useState(false);

  const token = useSelector((state) => state.api?.token);
  const api = useSelector((state) => state.api);
  const { subsidiaryId, subsidiaryName } = useSelector((state) => state.login.user);

  const { featureFlags } = useContext(PermissionsContext);
  const requests = {};

  const statsEnabled = getConfig("customConfig.stats.statsEnabled");
  const isBestClientsWidgetEnabled = featureFlags?.includes("best_clients_widget");

  const chartsByKey = [];
  const objectMode = resume[mode.key];

  for (const item in objectMode) {
    if (objectMode.hasOwnProperty(item)) {
      if (item === "customers" && !isBestClientsWidgetEnabled) continue;
      chartsByKey.push({ label: item, value: objectMode[item] });
    }
  }

  const formatVariation = (current, previous) => {
    const comparativeAmount = (current - previous) / previous;
    let formattedComparativeAmount = "-";
    if (isFinite(comparativeAmount)) {
      formattedComparativeAmount = __.number((current - previous) / previous, "0.00 %");
    }
    return formattedComparativeAmount;
  };

  useEffect(() => {
    if (!api) return;

    const endDate = mode.endDate || moment();
    const startDate = moment().subtract(mode.days, "d");
    const comparativeStartDate = moment().subtract(mode.days * 2, "d");
    const startQueryDate = moment().subtract(getConfig("customConfig.stats.startDateDaysSubtracted", 60), "d");

    setIsStarting(false);
    setIsFetchingResume(true);
    setIsFetchingSales(true);

    // Resume
    requests.resume && requests.resume.cancel();
    requests.resume = fetchResource(
      getResume(subsidiaryId, currencyCode, token),
      {
        token: token,
      },
      api
    )
      .onSuccess((data) => {
        if (data) {
          setResume(data);
          setIsFetchingResume(false);
          setCustomers(data[mode.key].newCustomers);

          requests.resume = null;
        }
      })
      .onFailure((error) => {
        setError(error);
        setIsFetchingSales(false);

        if (hasIn(error, "response.status") && error.response.status === 401) {
          props.history.push("/logout");
        }
      });

    // Sales
    requests.sales && requests.sales.cancel();
    requests.sales = fetchResource(
      getSales(subsidiaryId, currencyCode, token),
      {
        startDate: startQueryDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        token: token,
      },
      api
    )
      .onSuccess((data) => {
        if (data) {
          const newTotals = data["byDate"].reduce((result, item) => {
            if (moment(item.date).isBetween(startDate, endDate, "d", "(]")) {
              const amount = result.amount || 0;
              const count = result.count || 0;
              result.amount = amount + item.amount;
              result.count = count + item.count;
            } else if (
              moment(item.date).isBetween(
                comparativeStartDate,
                startDate,
                "d",
                "(]"
              )
            ) {
              const comparativeAmount = result.comparativeAmount || 0;
              const comparativeCount = result.comparativeAmount || 0;
              result.comparativeAmount = comparativeAmount + item.amount;
              result.comparativeCount = comparativeCount + item.count;
            }

            return result;
          }, {});
          const newPositive = (newTotals.amount >= newTotals.comparativeAmount);
          setPositive(newPositive);
          setSales(data);
          setTotals(newTotals);
          setIsFetchingSales(false);
          setError(null);
        }
      })
      .onFailure((error) => {
        setError(error);
        setIsFetchingSales(false);

        if (hasIn(error, "response.status") && error.response.status === 401) {
          props.history.push("/logout");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, token, currencyCode, subsidiaryId, props.history, mode.days, mode.endDate, mode.key, requests.resume, requests.sales])

  if (isStarting || isFetchingResume || isFetchingSales) {
    return <Loader />;
  }

  if (!mode) {
    return <div className="chart-message">Mode error</div>;
  }

  if (error) {
    return (
      <div className="dashboard-card">
        <div className="dashboard-card-body">
          <div className="dashboard-card-message">
            <i className="mdi mdi-minus-circle" />
            <h3>Sin datos</h3>
            <p>{error || "Hubo un problema"}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col lg="6" xs="12" className="flex-container">
          <Row className="flex-content">
            <Col lg="6" xs="12" className="flex-container">
              <CardChart
                size="lg"
                title={subsidiaryName}
                icon="mdi mdi-flag"
                content={moment().format("LLLL")}
              />
            </Col>
            <Col
              lg="6"
              xs="12"
              className={!!totals.count ? "flex-container" : "flex-container not-information"}
            >
              <CardChart
                size="lg"
                title={__.currency(totals.amount, sales.currencyCode, "auto")}
                icon="mdi mdi-cart"
                content={`${__.unit(totals.count, "transactions")} ${mode.metricSuffix}`}
              />
            </Col>
          </Row>
          <Row className="flex-content">
            <Col
              md="6"
              xs="12"
              className={customers ? "flex-container positive" : "flex-container not-information customers"}
            >
              <CardChart
                size="lg"
                title={customers ? `+${customers}` : __("dashboard.sections.home.noNewClients")}
                icon={customers ? "mdi mdi-arrow-up-bold-circle" : "mdi mdi-star-circle"}
                content={__("dashboard.sections.home.newClients")}
              />
            </Col>
            <Col
              md="6"
              xs="12"
              className={!!totals.comparativeAmount ? (positive ? "flex-container positive" : "flex-container") : "flex-container not-information"}
            >
              <CardChart
                size="lg"
                title={formatVariation(totals.amount || 0, totals.comparativeAmount || 0)}
                icon={totals.comparativeAmount ? (positive ? "mdi mdi-arrow-up-bold-circle" : "mdi mdi-arrow-down-bold-circle-outline") : "mdi mdi-star-circle"}
                content={totals.comparativeAmount ? __("dashboard.sections.home.lastPeriodComparison") : __("dashboard.sections.home.lastPeriodNoData")}
              />
            </Col>
          </Row>
        </Col>

        <Col lg="6" xs="12" className="flex-container card-lg justify-chart">
          <CardChart title={mode.salesTitle}>
            {mode.salesChart && (
              <mode.salesChart
                mode={mode}
                noData={
                  <EmptyCard
                    icon="mdi mdi-chart-bar"
                    message={__.bb("dashboard.sections.home.noSales")}
                  ></EmptyCard>
                }
                days={mode.days}
                resume={resume}
                sales={sales}
                currency={get(sales, "currencyCode")}
              />
            )}
          </CardChart>
        </Col>

        {statsEnabled.map((stat, index) => {
          const item = chartsByKey.find((item) => item.label === stat);
          if (item) {
            const title = `charts.${item.label}.title`;
            const emptyData = `charts.${item.label}.noData`;
            const isEmpty = !item.value || !item.value.length;

            return (
              <Col
                lg="6"
                xs="12"
                className="flex-container card-lg justify-chart"
                key={index}
              >
                {(isEmpty) ? (
                  <EmptyCard
                    title={__(title)}
                    icon="mdi mdi-view-list"
                    message={__.bb(emptyData)}
                  ></EmptyCard>
                ) : (
                  <>
                    {item.label === "staff" &&
                      <StaffCard
                        title={__(title)}
                        mode={mode}
                        item={item}
                      />
                    }
                    {item.label === "products" &&
                      <ProdcutCard
                        title={__(title)}
                        mode={mode}
                        item={item}
                      />
                    }
                    {item.label === "customers" &&
                      <CardChart title={__(title)}>
                        <DonutChart
                          noData={`${__("dashboard.sections.home.noProducts")} ${mode.metricSuffix}`}
                          data={item.value}
                          metric="quantity"
                          formatter={(value) => __.number(value)}
                        />
                      </CardChart>
                    }
                  </>
                )}
              </Col>
            );
          } else {
            return null;
          }
        })}
      </Row>
    </>
  );
}
